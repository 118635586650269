import React, { useEffect, useState } from 'react';
import { ITypeProduct } from '../../types/productsTypes';
import styles from './Product.module.css';
import Circle from '../common/Circle/Circle';

interface IProduct {
  activeProduct: ITypeProduct;
}

const Product = ({ activeProduct }: IProduct) => {
  const [activeImg, setActiveImg] = useState<string>(activeProduct.img[0]);
  const [activeSize, setActiveSize] = useState<string>(activeProduct.size[0]);
  const [activeColor, setActiveColor] = useState<string>(
    activeProduct.color[0]
  );
  const [activeColorName, setActiveColorName] = useState<string>(
    activeProduct.colorName[0]
  );
  const [activeTexture, setActiveTexture] = useState<string>(
    activeProduct.texture[0]
  );
  const [activeTextureName, setActiveTextureName] = useState<string>(
    activeProduct.textureName[0]
  );
  const [activePrice, setActivePrice] = useState<string>(
    activeProduct.price[0]
  );

  useEffect(() => {
    setActiveImg(activeProduct.img[0]);
    setActiveSize(activeProduct.size[0]);
    setActiveColor(activeProduct.color[0]);
    setActiveColorName(activeProduct.colorName[0]);
    setActiveTexture(activeProduct.texture[0]);
    setActiveTextureName(activeProduct.textureName[0]);
    setActivePrice(activeProduct.price[0]);
  }, [activeProduct]);

  return (
    <div className={styles.product}>
      <div className={styles.product_img}>
        <img src={`/assets/images/${activeImg}`} alt='product' />

        <img
          className={styles.product_img_crossLeft}
          src='/assets/images/cross_white.png'
          alt='cross'
        />
        <img
          className={styles.product_img_crossRight}
          src='/assets/images/cross_white.png'
          alt='cross'
        />
      </div>

      <div className={styles.product_material}>
        <div className={styles.product_material_size}>
          {activeProduct.size.map((item, index) => {
            return (
              <div
                className={
                  activeSize === item
                    ? `${styles.product_material_size_button}
                ${styles.product_material_size_button_active}`
                    : styles.product_material_size_button
                }
                key={index}
                onClick={() => {
                  setActiveSize(item);
                  setActivePrice(activeProduct.price[index]);
                }}
              >
                {item}
              </div>
            );
          })}
        </div>

        <div className={styles.product_material_appear}>
          <div className={styles.product_material_appear_item}>
            <p>Цвет:</p>

            <div className={styles.product_material_appear_item_circle}>
              {activeProduct.color.map((item, index) => {
                return (
                  <Circle
                    key={index}
                    background={item}
                    active={activeColor === item}
                    onClick={() => {
                      setActiveColor(item);
                      setActiveColorName(activeProduct.colorName[index]);
                      setActiveImg(activeProduct.img[index]);
                    }}
                  />
                );
              })}
            </div>
          </div>

          <div className={styles.product_material_appear_item}>
            <p>Текстура:</p>

            <div className={styles.product_material_appear_item_circle}>
              {activeProduct.texture.map((item, index) => {
                return (
                  <Circle
                    key={index}
                    background={item}
                    active={activeTexture === item}
                    onClick={() => {
                      setActiveTexture(item);
                      setActiveTextureName(activeProduct.textureName[index]);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>

        <div className={styles.product_material_result}>
          <p>Выбрано:</p>

          <div>
            <div className={styles.product_material_result_item}>
              <p>Цвет - </p>

              <p>{activeColorName}</p>
            </div>

            <div className={styles.product_material_result_item}>
              <p>Текстура - </p>

              <p>{activeTextureName}</p>
            </div>
          </div>
        </div>

        <div className={styles.product_material_result_price}>
          <p>Стоимость:</p>

          <div>
            <div className={styles.product_material_result_price_item}>
              <h3>{activePrice}</h3>

              <p>{activeProduct.priceDescription}</p>
            </div>

            <p>{activeProduct.info}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
