import React from 'react';
import styles from './ButtonLink.module.css';

interface ICustomLink {
  title: string;
  src: string;
  alt: string;
  onClick: () => void;
}

const ButtonLink = ({ title, src, alt, onClick }: ICustomLink) => {
  return (
    <div className={styles.customLink} onClick={onClick}>
      <h4>{title}</h4>

      <img src={`/assets/images/${src}`} alt={alt} />
    </div>
  );
};

export default ButtonLink;
