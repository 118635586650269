import React from 'react';
import axios from 'axios';
import pako from 'pako';
import styles from './Feedback.module.css';
import FeedbackForm from '../../common/FeedbackForm/FeedbackForm';

const Feedback = () => {
  const changeComa = (value: string | null) => {
    if (value === null) {
      return value;
    } else if (value.includes(',')) {
      value = value.replace(/,/g, '__');
      value = value.replace(/:/g, '--');
      return value;
    } else return value;
  };

  const compressString = (originalString: string) => {
    // @ts-ignore
    const compressedData = pako.gzip(originalString);
    // @ts-ignore
    const b64encoded_string = btoa(String.fromCharCode(...compressedData));
    return encodeURIComponent(b64encoded_string);
  };

  const uploadFiles = async (
    name: string,
    phone: string,
    email: string,
    message: string,
    selectedFiles: FileList | null
  ) => {
    const formData = new FormData();

    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append('files', selectedFiles[i]);
      }
    } else {
      const empty_file = new Blob([], { type: 'application/octet-stream' });
      formData.append('files', empty_file);
    }

    const v_name = changeComa(name);
    const v_phone = changeComa(phone);
    const v_email = changeComa(email);
    const v_message = changeComa(message);
    const finishString = compressString(
      `name:${v_name},phone:${v_phone},email:${v_email},message:${v_message}`
    );

    try {
      await axios.post(
        `https://prostup.by/feedback?item=${finishString}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('Отправлено');
    } catch (error) {
      console.error('Ошибка отправки', error);
    }
  };

  return (
    <div className={styles.feedback}>
      <h2>Остались вопросы или нужна консультация?</h2>

      <p>
        Напишите нам, прикрепите фото вашего крыльца и мы ответим вам в самое
        ближайшее время!
      </p>

      <FeedbackForm activeMessageWindow={true} sendData={uploadFiles} />
    </div>
  );
};

export default Feedback;
