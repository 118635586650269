import React, { useState } from 'react';
import styles from './PriceCalculation.module.css';
import InputRadio from '../common/InputRadio/InputRadio';
import Input from '../common/Input/Input';
import Button from '../common/Button/Button';
import Preloader from '../common/Preloader/Preloader';
import YourProduct from '../YourProduct/YourProduct';
import Container from '../common/Container/Container';

interface IPriceCalculation {
  scrollToElement: () => void;
}

const PriceCalculation = ({ scrollToElement }: IPriceCalculation) => {
  const tile = 68;
  const prostupMore = 68;
  const prostupLess = 62;
  const formOfStepImg: { [key: number]: string } = {
    1: 'scheme_1Side.png',
    2: 'scheme_2Side.png',
    3: 'scheme_3Side.png',
  };
  const formOfStep: string[] = ['Прямые', 'Г-образные', 'П-образные'];
  const [activeFormOfStepImg, setActiveFormOfStepImg] = useState(
    formOfStepImg[1]
  );
  const [activeFormOfStep, setActiveFormOfStep] = useState<string>(
    formOfStep[0]
  );
  const [countOfStep, setCountOfStep] = useState<number | string>('');
  const [allLengthOfStep, setAllLengthOfStep] = useState<number | string>('');
  const [widthOfStep, setWidthOfStep] = useState<number | string>(35);
  const [areaWidth, setAreaWidth] = useState<number | string>('');
  const [areaLength, setAreaLength] = useState<number | string>('');
  const [countOfTile, setCountOfTile] = useState<number>(0);
  const [squareOfTile, setSquareOfTile] = useState<number>(0);
  const [costProstupen, setCostProstupen] = useState<number>(0);
  const [costTile, setCostTile] = useState<number>(0);
  const [priceCalculationData, setPriceCalculationData] = useState({});
  const [preloader, setPreloader] = useState<boolean>(false);
  const [yourProduct, setYourProduct] = useState<boolean>(false);

  const priceData = () => {
    const formData = {
      form: activeFormOfStep,
      length: allLengthOfStep,
      variant: widthOfStep,
      count: countOfTile,
      square: squareOfTile,
      areaLen: areaLength,
      areaWid: areaWidth,
      allLength: allLengthOfStep,
      prostupLess: prostupLess,
      prostupMore: prostupMore,
      tile: tile,
    };

    setPriceCalculationData(formData);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setPreloader(true);
    setYourProduct(false);
    const allLengthOfStepValue =
      parseFloat(allLengthOfStep as string) / 100 || 0;
    const widthOfStepValue = parseFloat(widthOfStep as string) / 100 || 0;
    const areaWidthValue = parseFloat(areaWidth as string) / 100 || 0;
    const areaLengthValue = parseFloat(areaLength as string) / 100 || 0;
    const countSteps = Math.ceil(allLengthOfStepValue / 1.2);
    setCountOfTile(countSteps);
    const newSquare = parseFloat(
      (areaLengthValue * areaWidthValue * 1.1).toFixed(2)
    );
    setSquareOfTile(newSquare);
    const countTailValue = Math.ceil(newSquare * tile);
    if (widthOfStepValue === 35) {
      setCostProstupen(prostupLess * countSteps);
    } else {
      setCostProstupen(prostupMore * countSteps);
    }
    setCostTile(countTailValue);
    priceData();
  };

  return (
    <>
      <div id='priceCalculation' className={styles.priceCalculation}>
        <Container
          borderTop={true}
          borderBottom={true}
          crossTopLeft={true}
          crossTopRight={true}
          crossBottomRight={true}
          crossBottomLeft={true}
        >
          <div className={styles.priceCalculation_content}>
            <div className={styles.priceCalculation_content_scheme}>
              <div className={styles.priceCalculation_scheme}>
                <h3>Основные замеры:</h3>

                <img
                  className={styles.priceCalculation_scheme_img}
                  src={`/assets/images/${activeFormOfStepImg}`}
                  alt='scheme'
                />

                <p className={styles.priceCalculation_scheme_description}>
                  Если форма вашего крыльца отличается от предложенных
                  вариантов, выберите наиболее близкую форму или обратитесь к
                  менеджеру для <b>БЕСПЛАТНОГО</b> индивидуального расчёта
                  материалов.
                </p>
              </div>

              <div
                className={`${styles.priceCalculation_price_area} ${styles.priceCalculation_price_area_forMobile}`}
              >
                <h3>Площадка:</h3>

                <div className={styles.priceCalculation_price_sizeOfArea}>
                  <Input
                    label='Ширина (a, см):'
                    type='number'
                    placeholder='120'
                    value={areaWidth}
                    onChange={(e) => setAreaWidth(e.target.value)}
                  />

                  <Input
                    label='Глубина (b, см):'
                    type='number'
                    placeholder='100'
                    value={areaLength}
                    onChange={(e) => setAreaLength(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className={styles.priceCalculation_price}>
              <h2>Рассчитать стоимость</h2>

              <p>
                Здесь, внеся параметры ступеней Вы получите расчет количества и
                стоимости изделий на крыльцо, лестничный марш, террасу и пр.
              </p>

              <p
                className={`${styles.priceCalculation_scheme_description}
                    ${styles.priceCalculation_scheme_description_forMobile}`}
              >
                Если форма вашего крыльца отличается от предложенных вариантов,
                выберите наиболее близкую форму или обратитесь к менеджеру для{' '}
                <b>БЕСПЛАТНОГО</b> индивидуального расчёта материалов.
              </p>

              <div className={styles.priceCalculation_price_counting}>
                <h3>Размеры ступеней:</h3>

                <p>Форма ступеней:</p>

                <div className={styles.priceCalculation_price_typeOfStep}>
                  {formOfStep.map((item, index) => {
                    return (
                      <InputRadio
                        key={index}
                        label={item}
                        active={activeFormOfStep === item}
                        onClick={() => {
                          setActiveFormOfStep(item);
                          setActiveFormOfStepImg(formOfStepImg[index + 1]);
                        }}
                      />
                    );
                  })}
                </div>

                <img
                  className={`${styles.priceCalculation_scheme_img} ${styles.priceCalculation_scheme_img_forMobile}`}
                  src={`/assets/images/${activeFormOfStepImg}`}
                  alt='scheme'
                />

                <div className={styles.priceCalculation_price_sizeOfStep}>
                  <Input
                    label='Количество ступеней:'
                    type='number'
                    value={countOfStep}
                    placeholder='4'
                    onChange={(e) => setCountOfStep(e.target.value)}
                  />

                  <Input
                    label='Общая длина ступеней (L, см):'
                    type='number'
                    placeholder='120'
                    value={allLengthOfStep}
                    onChange={(e) => setAllLengthOfStep(e.target.value)}
                  />

                  <div className={styles.inputSelect}>
                    <label>Ширина ступени (см):</label>
                    <select
                      required
                      value={widthOfStep}
                      onChange={(e) => setWidthOfStep(e.target.value)}
                    >
                      <option>35</option>
                      <option>40</option>
                    </select>
                  </div>
                </div>

                <div className={styles.priceCalculation_price_area}>
                  <h3>Площадка:</h3>

                  <div className={styles.priceCalculation_price_sizeOfArea}>
                    <Input
                      label='Ширина (a, см):'
                      type='number'
                      placeholder='120'
                      value={areaWidth}
                      onChange={(e) => setAreaWidth(e.target.value)}
                    />

                    <Input
                      label='Глубина (b, см):'
                      type='number'
                      placeholder='100'
                      value={areaLength}
                      onChange={(e) => setAreaLength(e.target.value)}
                    />
                  </div>
                </div>

                <div className={styles.priceCalculation_price_calculation}>
                  <Button text='Рассчитать' onClick={handleSubmit} />

                  <p className={styles.priceCalculation_price_calculation_text}>
                    Данные расчёты являются приблизительными. Для точного
                    расчета по Вашему объекту{' '}
                    <button onClick={scrollToElement}>
                      обратитесь к менеджеру
                    </button>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`${styles.priceCalculation_price_calculation} ${styles.priceCalculation_price_calculation_forMobile}`}
          >
            <Button text='Рассчитать' onClick={handleSubmit} />

            <p className={styles.priceCalculation_price_calculation_text}>
              Данные расчёты являются приблизительными. Для точного расчета по
              Вашему объекту{' '}
              <button onClick={scrollToElement}>обратитесь к менеджеру</button>.
            </p>
          </div>
        </Container>
      </div>

      {preloader && (
        <Preloader
          onChange={() => {
            setPreloader(false);
            setYourProduct(true);
          }}
        />
      )}

      {yourProduct && (
        <YourProduct
          countOfTile={countOfTile}
          squareOfTile={squareOfTile}
          costProstupen={costProstupen}
          costTile={costTile}
          priceCalculationData={priceCalculationData}
        />
      )}
    </>
  );
};

export default PriceCalculation;
