import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import HomePage from './components/Pages/HomePage';
import OurProductPage from './components/Pages/OurProductPage';
import QualitativeCharacteristicsPage from './components/Pages/QualitativeCharacteristicsPage';
import OrderPaymentDeliveryPage from './components/Pages/OrderPaymentDeliveryPage';
import SizeChartPage from './components/Pages/SizeChartPage/SizeChartPage';

const App = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path='our-products' element={<OurProductPage />} />
          <Route
            path='qualitative-characteristics'
            element={<QualitativeCharacteristicsPage />}
          />
          <Route
            path='order-payment-delivery'
            element={<OrderPaymentDeliveryPage />}
          />
          <Route path='size-chart' element={<SizeChartPage />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
