import React, { useEffect } from 'react';
import { DataImgForCarousel } from '../../database/imgForCarousel';
import Hero from '../Hero/Hero';
import OurProductList from '../OurProductList/OurProductList';
import Slider from '../Slider/Slider';
import EmptyBlock from '../common/EmptyBlock/EmptyBlock';
import Connection from '../Connection/Connection';

const OurProductPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero title='Наши изделия' />
      <OurProductList />
      <Slider images={DataImgForCarousel} />
      <EmptyBlock />
      <Connection />
    </>
  );
};

export default OurProductPage;
