const _firstLastNameRegExp = /([А-ЯЁ]|[а-яё]|[a-z]|[A-Z])/;
const _emailLanguageRegExp = /^[A-z._@\s\-\d]+$/;
const _emailRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const REQUIRED_FIELD = 'Заполните обязательные поля';
const ENTER_NAME = 'Введите имя';
const WRONG_NAME = 'Почта должна содержать только латинские или русские буквы';
const WRONG_NAME_LENGTH = 'Имя должно быть не больше 60 символов';
const ENTER_PHONE = 'Введите номер телефона';
const ENTER_EMAIL = 'Введите почту';
const WRONG_EMAIL_LANGUAGE = 'Почта должна содержать только латинские буквы';
const WRONG_EMAIL = 'Почта должна содержать специальный символ @ и домен';
const WRONG_MESSAGE_LANGUAGE = 'Описание должно быть не больше 1000 символов';

const NOT_ERROR = '';

class ValidationService {
  public validateRequired(value: string): string {
    if (!value) {
      return REQUIRED_FIELD;
    }

    return NOT_ERROR;
  }

  public validateName(name: string): string {
    if (!name) {
      return ENTER_NAME;
    }

    if (!_firstLastNameRegExp.test(name)) {
      return WRONG_NAME;
    }

    if (name.length > 60) {
      return WRONG_NAME_LENGTH;
    }

    return NOT_ERROR;
  }

  public validatePhone(phone: string): string {
    if (!phone) {
      return ENTER_PHONE;
    }

    return NOT_ERROR;
  }

  public validateEmail(email: string): string {
    if (!email) {
      return ENTER_EMAIL;
    }

    if (!_emailLanguageRegExp.test(email)) {
      return WRONG_EMAIL_LANGUAGE;
    }

    if (!_emailRegExp.test(email)) {
      return WRONG_EMAIL;
    }

    return NOT_ERROR;
  }

  public validateMessage(message: string): string {
    if (message.length > 1000) {
      return WRONG_MESSAGE_LANGUAGE;
    }

    return NOT_ERROR;
  }
}

export const validationService = new ValidationService();
