import React, { useEffect, useState } from 'react';
import styles from './Preloader.module.css';
import Container from '../Container/Container';

interface IPreloader {
  onChange: () => void;
}

const Preloader = ({ onChange }: IPreloader) => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(timer);
          onChange();
          return prevProgress;
        } else {
          return prevProgress + 1;
        }
      });
    }, 50);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={styles.preloader}>
      <Container>
        <div className={styles.preloader_content}>
          <h2>Расчет стоимости</h2>

          <div className={styles.preloader_progress}>
            <h3>Расчет материалов</h3>

            <h3>{progress}%</h3>
          </div>

          <div className={styles.preloader_progressBar}>
            <div
              className={styles.preloader_progressBar_progress}
              style={{ maxWidth: `${progress}%` }}
            ></div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Preloader;
