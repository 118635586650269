import React, { FormEvent } from 'react';
import styles from './Button.module.css';

interface IButton {
  text: string;
  onClick: (e: FormEvent) => void;
}

const Button = ({ text, onClick }: IButton) => {
  return (
    <button className={styles.button} onClick={onClick}>
      {text}
    </button>
  );
};

export default Button;
