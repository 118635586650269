import React from 'react';
import styles from './Connection.module.css';
import OurContact from './OurContact/OurContact';
import Feedback from './Feedback/Feedback';
import Container from '../common/Container/Container';

interface IConnection {
  targetRef?: any;
}

const Connection = ({ targetRef }: IConnection) => {
  return (
    <div className={styles.connection} ref={targetRef}>
      <div className={styles.connection_img}></div>

      <Container borderTop={true} crossTopLeft={true} crossTopRight={true}>
        <div className={styles.connection_content}>
          <OurContact />

          <Feedback />
        </div>
      </Container>
    </div>
  );
};

export default Connection;
