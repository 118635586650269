import React from 'react';
import styles from './CustomContactLink.module.css';

interface ICustomContactLink {
  marginBottom?: number;
  href?: string;
  target?: boolean;
  src: string;
  alt: string;
  text: string;
}

const CustomContactLink = ({
  marginBottom,
  href,
  target = false,
  src,
  alt,
  text,
}: ICustomContactLink) => {
  return (
    <div
      className={styles.customContactLink}
      style={{
        marginBottom: `${marginBottom}rem`,
        cursor: `${href ? 'pointer' : ''}`,
      }}
    >
      <p>
        <a
          href={href}
          target={target ? '_blank' : '_self'}
          rel='noopener noreferrer'
        >
          {src ? (
            <img
              className={styles.customContactLink_img}
              src={`/assets/images/${src}`}
              alt={alt}
            />
          ) : null}
          {text}
        </a>
      </p>
    </div>
  );
};

export default CustomContactLink;
