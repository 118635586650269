import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DataProducts } from '../../database/products';
import { ITypeProduct } from '../../types/productsTypes';
import styles from './OurProduct.module.css';
import ButtonTab from '../common/ButtonTab/ButtonTab';
import Product from '../Product/Product';
import Button from '../common/Button/Button';
import Container from '../common/Container/Container';

const OurProduct = () => {
  const nameTab: string[] = ['Проступи', 'Плитка', 'Парапетные плиты'];
  const [activeTab, setActiveTab] = useState<string>(nameTab[0]);
  const [activeProduct, setActiveProduct] = useState<ITypeProduct>(
    DataProducts[0]
  );

  const handleTabClick = (item: string, index: number) => {
    setActiveTab(item);
    setActiveProduct(DataProducts[index]);
  };

  return (
    <div className={styles.ourProduct}>
      <Container>
        <div className={styles.ourProduct_content}>
          <h2>Наши изделия</h2>

          <div className={styles.ourProduct_tab}>
            {nameTab.map((item, index) => {
              return (
                <ButtonTab
                  key={index}
                  text={item}
                  activeTab={activeTab === item}
                  onClick={() => handleTabClick(item, index)}
                />
              );
            })}
          </div>

          <Product activeProduct={activeProduct} />

          <Link to='/our-products'>
            <Button text='Смотреть все изделия' onClick={() => {}} />
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default OurProduct;
