import React, { ReactNode } from 'react';
import styles from './Container.module.css';

interface IContainer {
  borderTop?: boolean;
  borderBottom?: boolean;
  crossTopLeft?: boolean;
  crossTopRight?: boolean;
  crossBottomRight?: boolean;
  crossBottomLeft?: boolean;
  children: ReactNode;
}

const Container = ({
  borderTop = false,
  borderBottom = false,
  crossTopLeft = false,
  crossTopRight = false,
  crossBottomRight = false,
  crossBottomLeft = false,
  children,
}: IContainer) => {
  const containerStyles = {
    borderTop: `${borderTop ? 1 : 0}px solid #777777`,
    borderBottom: `${borderBottom ? 1 : 0}px solid #777777`,
  };

  return (
    <div className={styles.container_wrapper} style={containerStyles}>
      <div className={styles.container}>
        {crossTopLeft && (
          <img
            className={styles.container_crossTopLeft}
            src='/assets/images/cross_white.png'
            alt='cross'
          />
        )}

        {crossTopRight && (
          <img
            className={styles.container_crossTopRight}
            src='/assets/images/cross_white.png'
            alt='cross'
          />
        )}

        {crossBottomRight && (
          <img
            className={styles.container_crossBottomRight}
            src='/assets/images/cross_white.png'
            alt='cross'
          />
        )}

        {crossBottomLeft && (
          <img
            className={styles.container_crossBottomLeft}
            src='/assets/images/cross_white.png'
            alt='cross'
          />
        )}

        {children}
      </div>
    </div>
  );
};

export default Container;
