import React, { useEffect } from 'react';
import Hero from '../../Hero/Hero';
import Container from '../../common/Container/Container';
import EmptyBlock from '../../common/EmptyBlock/EmptyBlock';
import Connection from '../../Connection/Connection';
import styles from './SizeChartPage.module.css';

const SizeChartPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero
        title='Схема размеров'
        text='Необходимые размеры бетонных оснований для монтажа изделий'
      />

      <div className={styles.installationRules}>
        <Container
          borderTop={true}
          borderBottom={true}
          crossTopLeft={true}
          crossTopRight={true}
        >
          <div className={styles.installationRules_grey}></div>

          <div className={styles.installationRules_content}>
            <div className={styles.installationRules_content_foundation}>
              <h3>Подготовка основания под проступи 1200/350/40</h3>

              <img src='/assets/images/foundation_img_1.png' alt='foundation' />
            </div>

            <div className={styles.installationRules_content_foundation}>
              <h3>Подготовка основания под проступи 1200/400/40</h3>

              <img src='/assets/images/foundation_img_2.png' alt='foundation' />
            </div>
          </div>
        </Container>
      </div>
      <EmptyBlock />
      <Connection />
    </>
  );
};

export default SizeChartPage;
