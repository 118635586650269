import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { validationService } from '../../../services/validation';
import styles from './FeedbackForm.module.css';
import Input from '../../common/Input/Input';
import Textarea from '../../common/Textarea/Textarea';
import Button from '../../common/Button/Button';

interface IFeedbackForm {
  activeMessageWindow: boolean;
  sendData: (...keys: any) => void;
}

const FeedbackForm = ({ activeMessageWindow, sendData }: IFeedbackForm) => {
  const [name, setName] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [fileList, setFileList] = useState<string>('Название_фото_крыльца_для');
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  const onChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setName(value);
    const error = validationService.validateName(value);
    setErrors((errors) => ({ ...errors, name: error }));
  }, []);

  const onChangePhone = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setPhone(value);
    const error = validationService.validatePhone(value);
    setErrors((errors) => ({ ...errors, phone: error }));
  }, []);

  const onChangeEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEmail(value);
    const error = validationService.validateEmail(value);
    setErrors((errors) => ({ ...errors, email: error }));
  }, []);

  const onChangeMessage = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setMessage(value);
      const error = validationService.validateMessage(value);
      setErrors((errors) => ({ ...errors, message: error }));
    },
    []
  );

  useEffect(() => {
    const maxSize = 1024 * 1024 * 10;
    if (selectedFiles) {
      for (let i = 0; i < selectedFiles.length; i++) {
        if (
          selectedFiles[i].size <= maxSize &&
          (selectedFiles[i].type === 'image/png' ||
            selectedFiles[i].type === 'image/jpeg')
        ) {
          setFileList(selectedFiles[0].name);
          if (selectedFiles.length > 1) {
            setFileList(`${selectedFiles.length} файла(ов) выбрано`);
          }
        } else {
          setSelectedFiles(null);
          setFileList('Неверный формат файл');
          return;
        }
      }
    }
  }, [selectedFiles, fileList]);

  const uploadFiles = () => {
    const errors: { [key: string]: string } = {
      name: validationService.validateName(name),
      phone: validationService.validatePhone(phone),
      email: validationService.validateEmail(email),
      message: validationService.validateMessage(message),
    };

    const allKeysAreZero = Object.values(errors).every((value) => value === '');

    if (allKeysAreZero) {
      sendData(name, phone, email, message, selectedFiles);
      setName('');
      setPhone('');
      setEmail('');
      setMessage('');
      setSelectedFiles(null);
      setFileList('Название_фото_крыльца_для');
    } else {
      return setErrors(errors);
    }
  };

  return (
    <div className={styles.feedbackForm}>
      <div className={styles.feedbackForm_content}>
        <Input
          label='Ваше имя'
          type='text'
          placeholder='Иван Петров'
          value={name}
          onChange={onChangeName}
          error={errors.name}
        />

        <div className={styles.feedbackForm_contact}>
          <div className={styles.feedbackForm_contact_phone}>
            <label>Телефон</label>

            <InputMask
              className={styles.feedbackForm_contact_phone_input}
              name='phone'
              placeholder='+375 (99) 999-99-99'
              mask='+375 (99) 999-99-99'
              maskChar='_'
              required
              value={phone}
              onChange={onChangePhone}
            />

            <p>{errors.phone}</p>
          </div>

          <Input
            label='Электронная почта'
            type='text'
            placeholder='example@gmail.com'
            value={email}
            onChange={onChangeEmail}
            error={errors.email}
          />
        </div>
      </div>

      {activeMessageWindow && (
        <>
          <Textarea
            label='Ваше сообщение'
            placeholder='Чем мы можем вам помочь?'
            value={message}
            onChange={onChangeMessage}
            error={errors.message}
          />

          <div className={styles.feedbackForm_imgAttach}>
            <div className={styles.feedbackForm_imgAttach_button}>
              <img
                className={styles.feedbackForm_imgAttach_button_img}
                src='/assets/images/icon_attach.png'
                alt='attach'
              />

              <label htmlFor='inputFile'>
                <p>Прикрепить фото</p>
              </label>

              <input
                className={styles.feedbackForm_imgAttach_input}
                type='file'
                id='inputFile'
                multiple
                onChange={(e) => setSelectedFiles(e.target.files)}
                accept='image/*,.jpeg,.png'
              />
            </div>

            <div className={styles.feedbackForm_imgAttach_list}>
              <img
                className={styles.feedbackForm_imgAttach_list_img}
                src='/assets/images/icon_img.png'
                alt='img'
              />

              <p className={styles.feedbackForm_imgAttach_list_text}>
                {fileList}
              </p>
            </div>
          </div>
        </>
      )}

      <Button text='Отправить' onClick={uploadFiles} />
    </div>
  );
};

export default FeedbackForm;
