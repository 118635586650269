import React from 'react';
import styles from './InstallationRules.module.css';
import Container from '../common/Container/Container';

const InstallationRules = () => {
  return (
    <div className={styles.installationRules}>
      <Container
        borderTop={true}
        borderBottom={true}
        crossTopLeft={true}
        crossTopRight={true}
      >
        <div className={styles.installationRules_grey}></div>

        <div className={styles.installationRules_content}>
          <div className={styles.installationRules_content_foundation}>
            <h3>Подготовка основания под проступи 1200/350/40</h3>

            <img src='/assets/images/foundation_img_1.png' alt='foundation' />
          </div>

          <div className={styles.installationRules_content_foundation}>
            <h3>Подготовка основания под проступи 1200/400/40</h3>

            <img src='/assets/images/foundation_img_2.png' alt='foundation' />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default InstallationRules;
