import React from 'react';
import { Link } from 'react-router-dom';
import styles from './CustomNavLink.module.css';

interface ICustomNavLink {
  to: string;
  title: string;
  onClick?: () => void;
}

const CustomNavLink = ({ to, title, onClick }: ICustomNavLink) => {
  return (
    <Link className={styles.customNavLink} to={to} onClick={onClick}>
      <h2 className={styles.customNavLink_title}>{title}</h2>

      <img
        className={styles.customNavLink_img}
        src={`/assets/images/icon_arrow_yellow.png`}
        alt='arrow'
      />
    </Link>
  );
};

export default CustomNavLink;
