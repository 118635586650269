import React from 'react';
import styles from './Construction.module.css';
import Card from '../common/Card/Card';
import ButtonLink from '../common/ButtonLink/ButtonLink';
import Container from '../common/Container/Container';

const Construction = () => {
  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = 'https://www.prostup.by/download-installation-rule';
    link.download = 'download-installation-rule.docx';
    link.click();
  };

  return (
    <div className={styles.construction}>
      <Container borderBottom={true} crossTopLeft={true} crossTopRight={true}>
        <div className={styles.construction_grey}></div>

        <div className={styles.construction_content}>
          <h3>Конструкция проступи</h3>

          <div className={styles.construction_content_scheme}>
            <img src='/assets/images/about_img.svg' alt='product' />

            <div>
              <img
                src='/assets/images/img_product_scheme_1.svg'
                alt='scheme_1'
              />

              <img
                src='/assets/images/img_product_scheme_2.svg'
                alt='scheme_2'
              />
            </div>
          </div>

          <div className={styles.construction_content_benefit}>
            <div className={styles.construction_content_benefit_cardList}>
              <div>
                <Card
                  title='Долговечность'
                  text='Качественные характеристики изделий обеспечивают срок их эксплуатации более 10 лет.'
                />

                <Card
                  title='Противоскользящая поверхность'
                  text='Группа противоскольжения С11. Эта характеристика критически важна для ступеней в условиях нашего климата.'
                />
              </div>

              <div>
                <Card
                  title='Цвета по каталогу RAL'
                  text='Позволяет сочетать наши изделия с другими элементами экстерьера: забор, крыша, фасад и прочие.'
                />
                <Card
                  title='Квалифицированный монтаж'
                  text='Наш квалифицированный монтаж является залогом надежности и долговечности любого проекта.'
                />
              </div>
            </div>

            <p>
              Вы можете обратиться к нам, либо выполнить монтаж самостоятельно с
              соблюдением правил монтажа
            </p>

            <ButtonLink
              title='Правила монтажа'
              src='icon_arrow.png'
              alt='arrow'
              onClick={downloadFile}
            />
          </div>

          <div className={styles.construction_content_quality}>
            <h3>Вы получите наилучшее качество</h3>

            <div className={styles.construction_content_quality_cardList}>
              <div className={styles.construction_content_quality_card}>
                <img src='/assets/images/icon_card_1.png' alt='icon' />

                <h4>Марка бетона М500</h4>

                <p>
                  Cоответствует классу прочности на сжатие С40/50. Это особо
                  прочная марка бетона используется для строительства метро,
                  плотин, фундаментов небоскребов.
                </p>
              </div>

              <div className={styles.construction_content_quality_card}>
                <img src='/assets/images/icon_card_2.png' alt='icon' />

                <h4>Армирование</h4>

                <p>
                  Изделия армированы композитной сеткой D3мм, с ячейкой
                  100х100мм, что также повышает его прочностные характеристики.
                </p>
              </div>

              <div className={styles.construction_content_quality_card}>
                <img src='/assets/images/icon_card_3.png' alt='icon' />

                <h4>Морозостойкость F200</h4>

                <p>
                  Означает, что изделия выдерживают не менее 200 циклов полной
                  заморозки и оттаивания. Эта характеристика особа ценна в
                  условиях нашего климата.
                </p>
              </div>

              <div className={styles.construction_content_quality_card}>
                <img src='/assets/images/icon_card_4.png' alt='icon' />

                <h4>Водопроницаемость W8</h4>

                <p>
                  Изделия не пропускают влагу при напоре воды в 8 атмосфер, т.е.
                  имеют особо низкую способность к проникновению жидкости.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Construction;
