import React from 'react';
import styles from './Textarea.module.css';

interface ITextarea {
  label: string;
  placeholder: string;
  value: string;
  onChange: (event: any) => void;
  error?: string;
}

const Textarea = ({
  label,
  placeholder,
  value,
  onChange,
  error = '',
}: ITextarea) => {
  return (
    <div className={styles.textarea}>
      <label>{label}</label>

      <textarea placeholder={placeholder} value={value} onChange={onChange} />

      <p>{error}</p>
    </div>
  );
};

export default Textarea;
