import React, { useEffect } from 'react';
import Hero from '../Hero/Hero';
import OrderPaymentDelivery from '../OrderPaymentDelivery/OrderPaymentDelivery';
import InstallationRules from '../InstallationRules/InstallationRules';
import EmptyBlock from '../common/EmptyBlock/EmptyBlock';
import Connection from '../Connection/Connection';

const OrderPaymentDeliveryPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero
        title='Заказ. Оплата. Доставка'
        text='Здесь вы можете ознакомиться с условиями заказа, оплаты и доставки наших изделий'
      />
      <OrderPaymentDelivery />
      <InstallationRules />
      <EmptyBlock />
      <Connection />
    </>
  );
};

export default OrderPaymentDeliveryPage;
