import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import HeroHome from '../HeroHome/HeroHome';
import EmptyBlock from '../common/EmptyBlock/EmptyBlock';
import AboutUs from '../AboutUs/AboutUs';
import PriceCalculation from '../PriceCalculation/PriceCalculation';
import OurProduct from '../OurProduct/OurProduct';
import OrderVisualization from '../OrderVisualization/OrderVisualization';
import Benefit from '../Benefit/Benefit';
import Connection from '../Connection/Connection';

const HomePage = () => {
  const targetRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const scrollToConnection = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <>
      <HeroHome scrollToElement={scrollToConnection} />
      <EmptyBlock grey={true} />
      <AboutUs />
      <PriceCalculation scrollToElement={scrollToConnection} />
      <OurProduct />
      <OrderVisualization scrollToElement={scrollToConnection} />
      <Benefit />
      <EmptyBlock />
      <Connection targetRef={targetRef} />
    </>
  );
};

export default HomePage;
