import React from 'react';
import styles from './EmptyBlock.module.css';
import Container from '../Container/Container';

interface IEmptyBlock {
  grey?: boolean;
}

const EmptyBlock = ({ grey = false }: IEmptyBlock) => {
  return (
    <div className={styles.emptyBlock}>
      <Container crossBottomRight={true} crossBottomLeft={true}>
        <div className={styles.emptyBlock_content}></div>

        {grey && <div className={styles.emptyBlock_grey}></div>}
      </Container>
    </div>
  );
};

export default EmptyBlock;
