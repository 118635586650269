import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AboutUs.module.css';
import Container from '../common/Container/Container';
import Button from '../common/Button/Button';

const AboutUs = () => {
  return (
    <div className={styles.aboutUs}>
      <div className={styles.aboutUs_img}></div>
      <Container borderTop={true} crossTopLeft={true} crossTopRight={true}>
        <div className={styles.aboutUs_grey}></div>

        <div className={styles.aboutUs_content}>
          <div className={styles.aboutUs_info}>
            <h2>Вы получите качество</h2>

            <h3>По доступной цене</h3>

            <p className={styles.aboutUs_info_description}>
              Марка бетона М500, соответствует классу прочности на сжатие
              С40/50. Эта особо прочная марка бетона используется для
              строительства метро, плотин, фундаментов небоскребов...
              <Link to={'qualitative-characteristics'}>Далее</Link>
            </p>

            <div className={styles.aboutUs_info_product}>
              <h5>62 BYN</h5>

              <p>за комплект проступь+подступенок</p>
            </div>

            <div className={styles.aboutUs_info_detailed}>
              <img
                className={styles.aboutUs_info_detailed_img}
                src='/assets/images/img_product.png'
                alt='product'
              />

              <Link to='our-products'>
                <Button text='Подробнее' onClick={() => {}} />
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;
