import React from 'react';
import axios from 'axios';
import styles from './YourProductSave.module.css';
import Container from '../../common/Container/Container';
import FeedbackForm from '../../common/FeedbackForm/FeedbackForm';

interface IYourProductSave {
  priceCalculationData: { [key: string]: any };
  onClose: () => void;
}

const YourProductSave = ({
  priceCalculationData,
  onClose,
}: IYourProductSave) => {
  const saveCalculation = async (
    name: string,
    phone: string,
    email: string
  ) => {
    priceCalculationData['name'] = name;
    priceCalculationData['phone'] = phone;
    priceCalculationData['email'] = email;

    try {
      await axios.post(`https://prostup.by/calculation`, priceCalculationData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      console.log('Отправлено');
      onClose();
    } catch (error) {
      console.error('Ошибка отправки', error);
    }
  };

  return (
    <div className={styles.yourProductSave}>
      <Container>
        <div className={styles.yourProductSave_cross}>
          <img
            src='/assets/images/icon_cross.png'
            alt='cross'
            onClick={onClose}
          />
        </div>

        <div className={styles.yourProductSave_content}>
          <div className={styles.yourProductSave_title}>
            <h2>Сохранить расчёт</h2>

            <p>Чтобы сохранить расчёт, пожалуйста, заполните свои данные.</p>
          </div>

          <FeedbackForm
            activeMessageWindow={false}
            sendData={saveCalculation}
          />
        </div>
      </Container>
    </div>
  );
};

export default YourProductSave;
