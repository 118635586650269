import React from 'react';
import styles from './Card.module.css';

interface ICard {
  title: string;
  text: string;
}

const Card = ({ title, text }: ICard) => {
  return (
    <div className={styles.card}>
      <div className={styles.card_title}>
        <img src='/assets/images/icon_arrow_yellow.png' alt='arrow' />

        <h4>{title}</h4>
      </div>

      <p>{text}</p>
    </div>
  );
};

export default Card;
