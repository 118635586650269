import React from 'react';
import styles from './Hero.module.css';
import Container from '../common/Container/Container';

interface IHero {
  title: string;
  text?: string;
}

const Hero = ({ title, text }: IHero) => {
  return (
    <div className={styles.hero}>
      <Container>
        <div className={styles.hero_content}>
          <h2
            className={
              text
                ? styles.hero_content_h2
                : `${styles.hero_content_h2} ${styles.hero_content_h2_active}`
            }
          >
            {title}
          </h2>

          {text && <p>{text}</p>}
        </div>
      </Container>

      <img
        className={styles.hero_img}
        src='/assets/images/hero_product_1920.png'
        alt='img'
      />
    </div>
  );
};

export default Hero;
