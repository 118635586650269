import React from 'react';
import styles from './InputRadio.module.css';

interface IInputRadio {
  label: string;
  active: boolean;
  onClick: () => void;
}

const InputRadio = ({ label, active, onClick }: IInputRadio) => {
  return (
    <div className={styles.inputRadio} onClick={onClick}>
      <img
        src={`/assets/images/${
          active ? 'icon_radio_checked.png' : 'icon_radio.png'
        } `}
        alt='radio'
      />

      <p>{label}</p>
    </div>
  );
};

export default InputRadio;
