import React from 'react';
import styles from './Circle.module.css';

interface ICircle {
  active: boolean;
  background: string;
  onClick: () => void;
}

const Circle = ({ active, background, onClick }: ICircle) => {
  return (
    <>
      {(background === '' && (
        <div className={styles.circle_text}>
          <p>По согласованию с менеджером</p>
        </div>
      )) || (
        <div
          className={
            active ? `${styles.border} ${styles.active}` : styles.border
          }
          onClick={onClick}
        >
          {(background.startsWith('#') && (
            <div
              className={styles.circle}
              style={{ background: `${background}` }}
            ></div>
          )) || (
            <img
              className={styles.circle}
              src={`/assets/images/${background}`}
              alt='color'
            />
          )}
        </div>
      )}
    </>
  );
};

export default Circle;
