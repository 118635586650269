import React from 'react';
import styles from './HeroHome.module.css';
import Button from '../common/Button/Button';
import Container from '../common/Container/Container';

interface IHero {
  scrollToElement: () => void;
}

const HeroHome = ({ scrollToElement }: IHero) => {
  return (
    <>
      <div className={styles.hero_header}></div>
      <div className={styles.hero}>
        <Container borderBottom={true}>
          <div className={styles.hero_content}>
            <div className={styles.hero_main}>
              <h1>Устали ежегодно переделывать крыльцо?</h1>

              <p>Закажите армированные проступи из тяжелого бетона!</p>

              <Button text='Заказать звонок' onClick={scrollToElement} />
            </div>

            <div className={styles.hero_news}>
              <h2>следите за акциями</h2>
            </div>
          </div>
        </Container>
        <div className={styles.hero_steps}></div>
      </div>
    </>
  );
};

export default HeroHome;
