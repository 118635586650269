import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import Container from '../common/Container/Container';
import CustomContactLink from '../common/CustomContactLink/CustomContactLink';

const Footer = () => {
  const [openDownloadMenu, setOpenDownloadMenu] = useState<boolean>(false);

  return (
    <div className={styles.footer}>
      <Container
        borderTop={true}
        borderBottom={true}
        crossTopLeft={true}
        crossTopRight={true}
      >
        <div className={styles.footer_content}>
          <div className={styles.footer_social}>
            <img
              className={styles.footer_logo}
              src='/assets/images/logo_white.png'
              alt='logo'
            />

            <div className={styles.footer_icon}>
              <p>
                <a
                  href='https://t.me/prostupby'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src='assets/images/icon_telegram.png' alt='telegram' />
                </a>
              </p>

              <p>
                <a
                  href='https://www.instagram.com/pro_stupeny/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=='
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src='assets/images/icon_instagram.png' alt='instagram' />
                </a>
              </p>
            </div>
          </div>

          <div className={styles.footer_list}>
            <div className={styles.footer_list_point}>
              <p>Быстрые ссылки</p>

              <Link to={'/our-products'}>
                <p>Наши изделия</p>
              </Link>

              <Link to={'/qualitative-characteristics'}>
                <p>Качественные характеристики</p>
              </Link>

              <Link to={'/order-payment-delivery'}>
                <p>Заказ. Оплата. Доставка</p>
              </Link>

              <Link to={'/size-chart'}>
                <p>Схема размеров</p>
              </Link>
            </div>

            <div className={styles.footer_list_point}>
              <p>Документы</p>

              <p onClick={() => setOpenDownloadMenu(!openDownloadMenu)}>
                Образец договора
              </p>

              {openDownloadMenu && (
                <>
                  <p>Для физических лиц</p>
                  <p>Для юридических лиц</p>
                </>
              )}

              <p>
                <a href='https://www.prostup.by/download-installation-rule'>
                  Правила монтажа
                </a>
              </p>

              <p>Политика конфиденциальности</p>
            </div>

            <div className={styles.footer_list_point}>
              <p>Контакты</p>

              <CustomContactLink
                src='icon_fax.png'
                alt='fax'
                text='+375152644000 (факс)'
              />

              <CustomContactLink
                href='tel:+375296733703'
                src='icon_phone.png'
                alt='phone'
                text='+375296733703'
              />

              <CustomContactLink
                href='mailto:info@prostup.by'
                src='icon_mail.png'
                alt='mail'
                text='info@prostup.by'
              />
            </div>
          </div>
        </div>
      </Container>

      <div className={styles.footer_bottom}>
        <Container>
          <p>© 2023 | ООО "ПрофиЦвет". УНП 590998793</p>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
