import React, { useState } from 'react';
import styles from './YourProduct.module.css';
import Container from '../common/Container/Container';
import YourProductSave from './YourProductSave/YourProductSave';
import Button from '../common/Button/Button';

interface IYourProduct {
  countOfTile: number;
  squareOfTile: number;
  costProstupen: number;
  costTile: number;
  priceCalculationData: { [key: string]: string };
}

const YourProduct = ({
  countOfTile,
  squareOfTile,
  costProstupen,
  costTile,
  priceCalculationData,
}: IYourProduct) => {
  const [save, setSave] = useState<boolean>(false);

  return (
    <div className={styles.yourProduct}>
      {save && (
        <YourProductSave
          priceCalculationData={priceCalculationData}
          onClose={() => setSave(false)}
        />
      )}

      <Container>
        <div className={styles.yourProduct_content}>
          <h2>Ваши изделия</h2>

          <div className={styles.yourProduct_result}>
            <div className={styles.yourProduct_result_product}>
              <div>
                <img
                  className={styles.yourProduct_result_product_img}
                  src='/assets/images/product_img_2.png'
                  alt='product'
                />

                <div className={styles.yourProduct_result_product_description}>
                  <p>Проступи:</p>

                  <p>{countOfTile} шт.</p>
                </div>
              </div>

              <div>
                <img
                  className={styles.yourProduct_result_product_img}
                  src='/assets/images/product_img_3.png'
                  alt='product'
                />
                <div className={styles.yourProduct_result_product_description}>
                  <p>Плитка:</p>

                  <p>{squareOfTile} м2</p>
                </div>
              </div>
            </div>

            <div className={styles.yourProduct_result_calculation}>
              <div className={styles.yourProduct_result_calculation_price}>
                <div
                  className={styles.yourProduct_result_calculation_price_sum}
                >
                  <p>Проступь + подступенок</p>
                  <div
                    className={
                      styles.yourProduct_result_calculation_price_sum_count
                    }
                  >
                    <p>{countOfTile} шт.</p>

                    <p>{costProstupen} BYN</p>
                  </div>
                </div>

                <div
                  className={styles.yourProduct_result_calculation_price_sum}
                >
                  <p>Плитка:</p>

                  <div
                    className={
                      styles.yourProduct_result_calculation_price_sum_count
                    }
                  >
                    <p>{squareOfTile} м2</p>

                    <p>{costTile} BYN</p>
                  </div>
                </div>
              </div>

              <div
                className={styles.yourProduct_result_calculation_price_total}
              >
                <p>Итого</p>

                <p>{costProstupen + costTile} BYN</p>
              </div>

              <Button text='Сохранить' onClick={() => setSave(true)} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default YourProduct;
