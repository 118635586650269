import React, { useEffect } from 'react';
import { DataDocForCarousel } from '../../database/imgForCarousel';
import Hero from '../Hero/Hero';
import SliderDoc from '../SliderDoc/SliderDoc';
import Construction from '../Construction/Construction';
import EmptyBlock from '../common/EmptyBlock/EmptyBlock';
import Connection from '../Connection/Connection';

const QualitativeCharacteristicsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Hero
        title='Качественные характеристики'
        text='Здесь вы можете ознакомиться с протоколами испытаний наших изделий'
      />
      <SliderDoc images={DataDocForCarousel} />
      <Construction />
      <EmptyBlock />
      <Connection />
    </>
  );
};

export default QualitativeCharacteristicsPage;
