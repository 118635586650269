export const DataImgForCarousel: string[] = [
  'carousel_img_1.png',
  'carousel_img_2.png',
  'carousel_img_3.png',
  'carousel_img_4.png',
  'carousel_img_5.png',
  'carousel_img_6.png',
  'carousel_img_7.png',
  'carousel_img_8.png',
  'carousel_img_9.png',
];

export const DataDocForCarousel: string[] = [
  'carousel_doc_1.png',
  'carousel_doc_2.png',
  'carousel_doc_3.png',
  'carousel_doc_4.png',
  'carousel_doc_5.png',
  'carousel_doc_6.png',
  'carousel_doc_7.png',
  'carousel_doc_8.png',
  'carousel_doc_9.png',
  'carousel_doc_10.png',
];
