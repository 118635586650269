import React from 'react';
import { Link } from 'react-router-dom';
import styles from './OrderPaymentDelivery.module.css';
import Container from '../common/Container/Container';

const OrderPaymentDelivery = () => {
  return (
    <div className={styles.orderPaymentDelivery}>
      <Container>
        <div className={styles.orderPaymentDelivery_content}>
          <div className={styles.orderPaymentDelivery_content_card}>
            <div className={styles.orderPaymentDelivery_content_card_title}>
              <div
                className={
                  styles.orderPaymentDelivery_content_card_title_number
                }
              >
                <h4>1</h4>
              </div>

              <h3>Заказ</h3>
            </div>

            <div
              className={styles.orderPaymentDelivery_content_card_description}
            >
              <p>
                Сроки производства изделий от 30 дней. С учетом этого, расчет и
                заказ необходимо делать заблаговременно.
              </p>

              <p>
                При необходимости, менеджер выезжает на объект, если сделать
                точные замеры и{' '}
                <Link to={'/#priceCalculation'}>Рассчитать стоимость</Link> не
                представляется возможным.
              </p>
            </div>
          </div>

          <div className={styles.orderPaymentDelivery_content_card}>
            <div className={styles.orderPaymentDelivery_content_card_title}>
              <div
                className={
                  styles.orderPaymentDelivery_content_card_title_number
                }
              >
                <h4>2</h4>
              </div>

              <h3>Оплата</h3>
            </div>

            <div
              className={styles.orderPaymentDelivery_content_card_description}
            >
              <p>
                После согласования количества и стоимости изделий, заключается{' '}
                <b>Договор</b>, в рамках которого вносится предоплата в размере
                50%. Оставшаяся часть оплачивается по факту отгрузки изделий.
              </p>

              <p>
                Работаем с кредитами и картами рассрочки: Халва, Моцная карта,
                КартаFUN.
              </p>
            </div>
          </div>

          <div className={styles.orderPaymentDelivery_content_card}>
            <div className={styles.orderPaymentDelivery_content_card_title}>
              <div
                className={
                  styles.orderPaymentDelivery_content_card_title_number
                }
              >
                <h4>3</h4>
              </div>

              <h3>Доставка</h3>
            </div>

            <div
              className={styles.orderPaymentDelivery_content_card_description}
            >
              <p>
                Доставляем изделия по всей территории Республики Беларусь.
                Упаковка и погрузка производится нашими силами, но доставка
                оплачивается Заказчиком отдельно.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OrderPaymentDelivery;
