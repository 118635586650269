import React from 'react';
import styles from './OrderVisualization.module.css';
import Container from '../common/Container/Container';
import Button from '../common/Button/Button';

interface IOrderVisualization {
  scrollToElement: () => void;
}

const OrderVisualization = ({ scrollToElement }: IOrderVisualization) => {
  return (
    <div className={styles.orderVisualization}>
      <Container
        borderTop={true}
        borderBottom={true}
        crossTopLeft={true}
        crossBottomLeft={true}
      >
        <div className={styles.orderVisualization_grey}></div>

        <div className={styles.orderVisualization_content}>
          <div className={styles.orderVisualization_content_text}>
            <h2>Хотите увидеть, как будет выглядеть ваше крыльцо?</h2>

            <p>
              Можете направить фото (лучше несколько) Вашего объекта, и добавить
              комментарии по цвету, фактуре и другие. Услуга платная.
            </p>
          </div>

          <Button text='Заказать визуализацию' onClick={scrollToElement} />
        </div>
      </Container>
    </div>
  );
};

export default OrderVisualization;
