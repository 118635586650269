import React from 'react';
import styles from './OurContact.module.css';
import CustomContactLink from '../../common/CustomContactLink/CustomContactLink';

const OurContact = () => {
  return (
    <div className={styles.ourContact}>
      <h3>Наши контакты</h3>

      <p>
        Свяжитесь с ними удобным для Вас способом, и мы максимально оперативно
        ответим на Ваши вопросы.
      </p>

      <CustomContactLink
        src='icon_fax.png'
        alt='fax'
        text='+375152644000 (факс)'
      />

      <CustomContactLink
        href='tel:+375296733703'
        src='icon_phone.png'
        alt='phone'
        text='+375296733703'
      />

      <CustomContactLink
        href='mailto:info@prostup.by'
        src='icon_mail.png'
        alt='mail'
        text='info@prostup.by'
      />

      <CustomContactLink
        marginBottom={2}
        href='https://www.instagram.com/pro_stupeny/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=='
        target={true}
        src='icon_instagram.png'
        alt='mail'
        text='@pro_stupeny'
      />

      <CustomContactLink
        src='icon_geo.png'
        alt='geo'
        text='г. Гродно, ул Пушкина 31А - 8'
      />
    </div>
  );
};

export default OurContact;
