import React, { useState } from 'react';
import styles from './BurgerMenu.module.css';
import CustomNavLink from '../common/CustomNavLink/CustomNavLink';
import ButtonLink from '../common/ButtonLink/ButtonLink';

interface IBurgerMenu {
  closeBurgerMenu: () => void;
}

const BurgerMenu = ({ closeBurgerMenu }: IBurgerMenu) => {
  const [openDownloadMenu, setOpenDownloadMenu] = useState<boolean>(false);

  return (
    <div className={styles.burgerMenu}>
      <div className={styles.burgerMenu_wrapper}>
        <div className={styles.burgerMenu_container}>
          <div className={styles.burgerMenu_content}>
            <div className={styles.burgerMenu_navBar}>
              <CustomNavLink
                to='/our-products'
                title='Наши изделия'
                onClick={closeBurgerMenu}
              />

              <CustomNavLink
                to='/qualitative-characteristics'
                title='Качественные характеристики'
                onClick={closeBurgerMenu}
              />

              <CustomNavLink
                to='/order-payment-delivery'
                title='Заказ. Оплата. Доставка'
                onClick={closeBurgerMenu}
              />

              <CustomNavLink
                to='/size-chart'
                title='Схема размеров'
                onClick={closeBurgerMenu}
              />

              <div className={styles.burgerMenu_navBar_download}>
                <div
                  className={styles.burgerMenu_navBar_download_menu}
                  onClick={() => setOpenDownloadMenu(!openDownloadMenu)}
                >
                  <h2>Договор</h2>

                  <img
                    className={styles.burgerMenu_navBar_download_menu_img}
                    src={`/assets/images/icon_arrow_yellow.png`}
                    alt='arrow'
                  />
                </div>

                {openDownloadMenu && (
                  <div className={styles.burgerMenu_navBar_download_document}>
                    <ButtonLink
                      title='Для физических лиц'
                      src='icon_arrow.png'
                      alt='arrow'
                      onClick={() => {}}
                    />

                    <ButtonLink
                      title='Для юридических лиц'
                      src='icon_arrow.png'
                      alt='arrow'
                      onClick={() => {}}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={styles.burgerMenu_social}>
              <p>
                <a
                  href='https://t.me/prostupby'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src='assets/images/icon_telegram.png' alt='telegram' />
                </a>
              </p>

              <p>
                <a
                  href='https://www.instagram.com/pro_stupeny/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA=='
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src='assets/images/icon_instagram.png' alt='instagram' />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
