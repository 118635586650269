import React from 'react';
import './SliderDoc.css';
import Container from '../common/Container/Container';
import SliderSlickDoc from 'react-slick';

interface ISlider {
  images: string[];
}

const SliderDoc = ({ images }: ISlider) => {
  const settingsDoc = {
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    className: 'slickSliderDoc',
    dotsClass: 'slickSliderDoc_dots',
    nextArrow: (
      <div>
        <img src='/assets/images/arrow_right.png' alt='arrow_left' />
      </div>
    ),

    prevArrow: (
      <div>
        <img src='/assets/images/arrow_left.png' alt='arrow_left' />
      </div>
    ),
    dots: true,

    customPaging: function (i: number) {
      return (
        <div>
          <img src={`/assets/images/carousel_doc_${i + 1}.png`} alt='icon' />
        </div>
      );
    },
  };

  return (
    <div className='sliderDoc'>
      <Container borderBottom={true}>
        <div className='sliderDoc_content'>
          <h3>Протокол испытаний</h3>

          <SliderSlickDoc {...settingsDoc}>
            {images.map((item, index) => {
              return (
                <img key={index} src={`/assets/images/${item}`} alt='slide' />
              );
            })}
          </SliderSlickDoc>
        </div>
      </Container>
    </div>
  );
};

export default SliderDoc;
