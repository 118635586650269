import React from 'react';
import { DataProducts } from '../../database/products';
import styles from './OurProductList.module.css';
import Container from '../common/Container/Container';
import Product from '../Product/Product';

const OurProductList = () => {
  return (
    <div className={styles.ourProductList}>
      <Container borderBottom={true}>
        <div className={styles.ourProductList_content}>
          {Object.keys(DataProducts).map((item, index) => {
            const product = DataProducts[Number(item)];
            return (
              <div className={styles.ourProductList_content_list} key={index}>
                <h3>{product.title}</h3>

                <Product activeProduct={product} />
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default OurProductList;
