import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './Header.module.css';
import Container from '../common/Container/Container';
import BurgerMenu from '../BurgerMenu/BurgerMenu';

const Header = () => {
  const [activeBurgerMenu, setActiveBurgerMenu] = useState<boolean>(false);
  const [headerOtherPage, setHeaderOtherPage] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const goHome = () => navigate('/');

  useEffect(() => {
    if (location.pathname !== '/') {
      setHeaderOtherPage(true);
    } else {
      setHeaderOtherPage(false);
    }
  }, [location]);

  if (activeBurgerMenu) {
    document.body.classList.add('blockScroll');
  } else {
    document.body.classList.remove('blockScroll');
  }

  return (
    <div
      className={
        activeBurgerMenu
          ? `${styles.header} ${styles.headerActive}`
          : `${styles.header}`
      }
    >
      <Container borderBottom={true}>
        <div className={styles.header_content}>
          <img
            className={styles.header_cross_left}
            src={`/assets/images/${
              activeBurgerMenu || headerOtherPage
                ? 'cross_white.png'
                : 'cross_dark.png'
            }`}
            alt='cross'
          />

          <img
            className={styles.header_cross_right}
            src={`/assets/images/${
              activeBurgerMenu || headerOtherPage
                ? 'cross_white.png'
                : 'cross_dark.png'
            }`}
            alt='cross'
          />

          <img
            className={styles.header_logo}
            src={`/assets/images/${
              activeBurgerMenu || headerOtherPage
                ? 'logo_white.png'
                : 'logo_dark.png'
            }`}
            alt='logo'
            onClick={goHome}
          />

          <img
            className={styles.header_burgerMenu}
            src={`/assets/images/${
              activeBurgerMenu
                ? 'icon_cross.png'
                : `${
                    headerOtherPage ? 'burgerMenu_white.png' : 'burgerMenu.png'
                  }`
            }`}
            alt='burgerMenu'
            onClick={() => {
              setActiveBurgerMenu(!activeBurgerMenu);
            }}
          />
        </div>
      </Container>

      {activeBurgerMenu && (
        <BurgerMenu closeBurgerMenu={() => setActiveBurgerMenu(false)} />
      )}
    </div>
  );
};

export default Header;
