import React from 'react';
import styles from './ButtonTab.module.css';

interface IButtonTab {
  text: string;
  activeTab: boolean;
  onClick: () => void;
}

const ButtonTab = ({ text, activeTab, onClick }: IButtonTab) => {
  return (
    <button
      className={
        activeTab ? `${styles.buttonTab} ${styles.active}` : styles.buttonTab
      }
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default ButtonTab;
