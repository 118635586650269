import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Benefit.module.css';
import Container from '../common/Container/Container';
import Card from '../common/Card/Card';
import Button from '../common/Button/Button';
import ButtonLink from '../common/ButtonLink/ButtonLink';

const Benefit = () => {
  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = 'https://www.prostup.by/download-installation-rule';
    link.download = 'download-installation-rule.docx';
    link.click();
  };

  return (
    <div className={styles.benefit}>
      <Container borderBottom={true}>
        <div className={styles.benefit_content}>
          <div className={styles.benefit_listLink}>
            <h2>Почему выбирают нас?</h2>

            <div>
              <Card
                title='долговечность'
                text='Качественные характеристики изделий обеспечивают срок их эксплуатации более 10 лет.'
              />
              <Card
                title='противоскользящая поверхность'
                text='Группа противоскольжения С11. Эта характеристика критически важна для ступеней крылец в условиях нашего климата.'
              />
              <Card
                title='Цвета по каталогу RAL'
                text='Позволяет сочетать наши изделия с другими элементами экстерьера: забор, крыша, фасад и прочие.'
              />
              <Card
                title='квалифицированный монтаж'
                text='Наш квалифицированный монтаж является залогом надежности и долговечности любого проекта.'
              />

              <p>
                Вы можете обратиться к нам, либо выполнить монтаж самостоятельно
                с соблюдением правил монтажа
              </p>
            </div>
          </div>

          <div className={styles.benefit_button}>
            <Link to={'/qualitative-characteristics'}>
              <Button text='Качественные характеристики' onClick={() => {}} />
            </Link>

            <ButtonLink
              title='Правила монтажа'
              src='icon_arrow.png'
              alt='arrow'
              onClick={downloadFile}
            />
          </div>
        </div>
      </Container>
      <div className={styles.benefit_img}></div>
    </div>
  );
};

export default Benefit;
